import yup from '@/services/Forms/Schemas/BaseSchema';

const schema = yup

	.object()
	.shape({
		vehicleBrand: yup.object().shape({
			id: yup.number().required('forms:vehicleRegistration.validation.vehicleBrand.required'),
			name: yup.string().required('forms:vehicleRegistration.validation.vehicleBrand.required'),
		}),
	})
	.required();
export default schema;
