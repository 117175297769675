import { useRouter } from 'next/router';
import React from 'react';

import { getRegexPatterns, UNITED_KINGDOM } from '@/constants/locales';
import { useUser } from '@/services/Hooks/useUser';

const useLocalizedRegex = () => {
	const { locale = UNITED_KINGDOM } = useRouter();
	const { userDetails } = useUser();
	const isDealershipRamstein = Boolean(userDetails?.is_ramstein_active);

	return React.useMemo(
		() => getRegexPatterns(locale, isDealershipRamstein),
		[locale, isDealershipRamstein]
	);
};

export default useLocalizedRegex;
