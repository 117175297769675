import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faExclamation, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import IInput from '@/components/Input/index.d';
import ErrorLabel from '@/components/Label/ErrorLabel';
import ISelect from '@/components/Select/index.d';
import { Caption } from '@/components/Text';
import { filterInputProps } from '@/services/Utils/Events/FilterComponents';

const getVariant = (variant: ISelect.Variant) => {
	switch (variant) {
		case 'success':
			return {
				border: 'border-success',
				text: 'text-success',
			};
		case 'error':
			return {
				border: 'border-error',
				text: 'text-error',
			};
		default:
			return {
				border: 'border-tone-contrast-50',
				text: 'text-primary-content',
			};
	}
};

const Input = forwardRef<HTMLInputElement, IInput.IProps>((p, ref) => {
	const {
		placeholder,
		type = 'text',
		errors,
		onChange,
		onFocus,
		onBlur,
		name,
		variant = 'default',
		value,
		disabled,
		customBackground = 'transparent',
		customInput = false,
		customInputText = 'UK',
		customInputImage,
		errorClassName = '',
	} = p;

	// these keys causing camelcase error when it s passed to dom so it needs to be deleted from 'p' object.
	// https://stackoverflow.com/questions/49358560/react-wrapper-react-does-not-recognize-the-staticcontext-prop-on-a-dom-elemen
	const {
		showLoader = false,
		testId,
		withButton = false,
		buttonLabel = '',
		onButtonClick,
		buttonId = '',
		buttonDisabled,
		disableError = false,
		buttonShowLoader,
		buttonTestId,
		customErrorLabelVariant = 'error',
		isSearchInput = false,
		...rest
	} = p;

	const filteredProps = filterInputProps(rest);

	const { border, text } = getVariant(variant);
	const isError = variant === 'error';
	const isSuccess = variant === 'success';

	// eslint-disable-next-line consistent-return
	const RenderLoaders = () => {
		if (customInput) {
			return (
				<div className="bg-primary-content absolute right-0 h-full rounded-r-full px-1 flex items-center justify-center gap-x-0.5">
					<Image src={customInputImage ?? ''} width={24} height={18} />
					<Caption variant="secondary">{customInputText}</Caption>
				</div>
			);
		}
		return (
			<>
				{showLoader ? (
					<Icon
						iconName={faSpinner}
						className={`mx-1 z-10 animate-spin ${getVariant(variant).text}`}
					/>
				) : (
					<>
						{isError && (
							<Icon
								variant="error"
								iconName={faExclamation}
								className={`absolute right-1 mr-0.75 w-5 h-5 ${text}`}
							/>
						)}
						{isSuccess && (
							<Icon
								variant="success"
								iconName={faCheck}
								className={`absolute right-1 mr-0.75 w-5 h-5 ${text}`}
							/>
						)}
						{!showLoader && !isSuccess && !isError && isSearchInput && (
							<Icon iconName={faSearch} className="absolute right-1 mr-0.75 w-4 h-4" />
						)}
					</>
				)}
			</>
		);
	};

	return (
		<>
			<div className="flex flex-row">
				<div
					className={`relative flex bg-reverse flex-grow flex-row border rounded-full items-center align-middle hover:shadow-hover focus-within:shadow-focus ${border}`}
				>
					<input
						ref={ref}
						name={name}
						onBlur={onBlur}
						onFocus={onFocus}
						value={value}
						type={type}
						placeholder={placeholder}
						id={name}
						className={`bg-${customBackground} text-n0 font-normal placeholder-tone-contrast-30 font-text whitespace-pre-line border-none rounded-full py-0.75 px-1 pr-2 w-full focus:outline-none disabled:bg-tertiary text-ellipsis`}
						onChange={onChange}
						disabled={disabled}
						data-testid={testId}
						{...filteredProps}
					/>

					<RenderLoaders />
				</div>
				{withButton && (
					<div data-cy="InputButtonContainer" className="ml-0.5 flex">
						<Button
							type="button"
							id={buttonId}
							onClick={onButtonClick}
							label={buttonLabel}
							variant="accent"
							width="content"
							data-testid={buttonTestId}
							size="medium"
							disabled={buttonDisabled}
							showLoader={buttonShowLoader}
						/>
					</div>
				)}
			</div>
			{!disableError && errors && (
				<ErrorLabel
					error={errors}
					role="alert"
					variant={customErrorLabelVariant}
					customClassName={errorClassName}
				/>
			)}
		</>
	);
});

export default Input;
