import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Card from '@/components/Card';
import Container from '@/components/Container';
import Image from '@/components/Image';
import { Body, Headline, LargeBody } from '@/components/Text';
import IHowBumperWorks from '@/modules/Home/Drivers/HowBumperWorks/index.d';

const getStepList = (t: (arg0: string) => string) => [
	{
		className: 'md:border-r md:border-tertiary',
		imageClassName: 'md:rounded-tl-sm',
		imagePath: 'images/home/businessanddriver/HowBumperWorksStep1.jpeg',
		text: t('howBumperWorks.step1'),
	},
	{
		className: 'md:border-r md:border-tertiary',
		imagePath: 'images/home/businessanddriver/HowBumperWorksStep2.jpeg',
		text: t('howBumperWorks.step2'),
	},
	{
		className: 'md:rounded-tr-sm',
		imageClassName: 'rounded-tr-sm',
		imagePath: 'images/home/businessanddriver/HowBumperWorksStep3.jpeg',
		text: t('howBumperWorks.step3'),
	},
];

const HowBumperWorks: FC<IHowBumperWorks.IProps> = ({
	textVariant = 'primary',
	containerVariant = 'reverse',
}) => {
	const { t } = useTranslation('acquisition');
	const stepList = getStepList(t);

	return (
		<Container width="default" variant={containerVariant} className="py-2.5 lg:py-4">
			<Headline weight="bold" variant={textVariant}>
				{t('howBumperWorks.title')}
			</Headline>
			<LargeBody variant={textVariant} className="pt-1 max-w-xl">
				{t('howBumperWorks.description')}
			</LargeBody>

			<div className="mt-3 border border-tertiary rounded-sm">
				<div className="grid grid-cols-1 md:grid-cols-3">
					{stepList.map((step) => (
						<Card structure="no-structure" className={step.className} noBorder>
							<div className="relative h-64">
								<Image
									src={step.imagePath}
									layout="fill"
									objectFit="cover"
									className={step?.imageClassName}
								/>
							</div>

							<Body className="h-28 md:h-36 lg:h-28 p-2 flex items-center">{step.text}</Body>
						</Card>
					))}
				</div>
				<div className="bg-tertiary rounded-b-sm p-0.75">
					<div className="md:flex md:justify-center md:items-center text-center font-headline text-n5">
						<div>
							<span className="text-tone-contrast">{t('howBumperWorks.sloganInvoice')}</span>
							<span className="pl-1 text-accent">{t('howBumperWorks.sloganPaid')}</span>
						</div>
						<div className="md:w-20" />
						<div>
							<span className="text-primary">{t('howBumperWorks.sloganNoInterest')}</span>
							<span className="pl-1 text-primary">{t('howBumperWorks.sloganNoWorries')}</span>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default HowBumperWorks;
