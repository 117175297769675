import { LOCALE_LIST, UNITED_KINGDOM } from '@/constants/locales';

const canonicalGenerator = (locale: string, pathname: string) => {
	let cannonical = process.env.NEXT_PUBLIC_SITE_URL + pathname;
	if (![UNITED_KINGDOM].includes(locale) && LOCALE_LIST.includes(locale))
		cannonical = `${process.env.NEXT_PUBLIC_SITE_URL}/${locale}${pathname}`;

	return cannonical;
};

export default canonicalGenerator;
