import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import {
	faCarBurst,
	faCarSideBolt,
	faCarWrench,
	faCheck,
	faGarage,
} from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import Container from '@/components/Container';
import { Variant as ContainerVariant } from '@/components/Container/index.d';
import Icon from '@/components/Icon';
import { Variant as IconVariant } from '@/components/Icon/index.d';
import Image from '@/components/Image';
import ImageContainer from '@/components/ImageContainer';
import { Body, Headline, LargeBody } from '@/components/Text';
import { Variant } from '@/components/Text/index.d';

const servicePartners = (t: (arg0: string) => string) => [
	{
		icon: {
			id: 1,
			src: faGarage,
		},
		id: 'franchise',
		text: t('servicePartners.servising.franchise.text'),
		title: t('servicePartners.servising.franchise.title'),
	},
	{
		icon: {
			id: 2,
			src: faCarWrench,
		},
		id: 'independent',
		text: t('servicePartners.servising.independent.text'),
		title: t('servicePartners.servising.independent.title'),
	},
	{
		icon: {
			id: 3,
			src: faCarBurst,
		},
		id: 'shops',
		text: t('servicePartners.servising.shops.text'),
		title: t('servicePartners.servising.shops.title'),
	},
	{
		icon: {
			id: 4,
			src: faCarSideBolt,
		},
		id: 'repairers',
		text: t('servicePartners.servising.repairers.text'),
		title: t('servicePartners.servising.repairers.title'),
	},
];

interface IProps {
	textVariant?: Variant;
	containerVariant?: ContainerVariant;
	mapContainerTextVariant?: Variant;
	iconVariant?: IconVariant;
	mapContainerIconVariant?: IconVariant;
}

const ServicePartners: FC<IProps> = ({
	containerVariant = 'secondary',
	textVariant = 'secondary',
	iconVariant = 'primary',
	mapContainerTextVariant = 'secondary',
	mapContainerIconVariant = 'secondary',
}) => {
	const { t } = useTranslation('acquisition');
	const router = useRouter();
	const itemList = servicePartners(t);

	return (
		<>
			<Container width="default" variant={containerVariant} className="py-2.5 lg:py-4">
				<div className="grid grid-cols-2 gap-x-3">
					<div className="col-span-2 lg:col-span-1">
						<Headline weight="bold" className="text-n4" variant={textVariant}>
							{t('servicePartners.servising.title')}
						</Headline>
						<Body variant={textVariant} className="pt-1">
							{t('servicePartners.servising.text')}
						</Body>
						<div className="grid grid-cols-2 gap-x-1.5 lg:gap-x-0">
							{itemList.map((item) => (
								<div key={item?.id} className="col-span-1 py-2">
									<Icon iconName={item.icon.src} variant={iconVariant} size="2x" />
									<LargeBody variant={textVariant} weight="bold" className="py-0.5">
										{item?.title}
									</LargeBody>
									<Body variant={textVariant}>{item?.text}</Body>
								</div>
							))}
						</div>
					</div>
					<div className="col-span-2 lg:col-span-1">
						<div className="grid grid-cols-8">
							<div className="col-span-5 lg:col-span-4 bg-secondary-contrast rounded-l-sm py-1.5 lg:py-6 px-1.5">
								<LargeBody weight="bold" variant={mapContainerTextVariant}>
									{t('servicePartners.partners.title')}
								</LargeBody>
								<Body variant={mapContainerTextVariant} className="pt-0.5 pb-1.5">
									{t('servicePartners.partners.text')}
								</Body>
								<div className="flex items-center gap-x-0.5">
									<Icon iconName={faCheck} variant={mapContainerIconVariant} />
									<Body variant={mapContainerTextVariant}>
										{t('servicePartners.partners.coverage')}
									</Body>
								</div>
								<div className="flex items-center gap-x-0.5 pt-1 pb-1.5">
									<Icon iconName={faCheck} variant={mapContainerIconVariant} />
									<Body variant={mapContainerTextVariant}>
										{t('servicePartners.partners.drivers')}
									</Body>
								</div>
								<Button
									label={t('servicePartners.partners.button')}
									width="content"
									icon={faLongArrowRight}
									iconPosition="right"
									size="medium"
									onClick={() => router.push('/locations')}
								/>
							</div>
							<div className="col-span-3 lg:col-span-4 h-full relative">
								<ImageContainer
									className="h-full"
									wrapperClassName="h-full rounded-r-sm"
									variant="slotted-tread-secondary"
									bgSize="8"
									spacing="none"
									width="full"
								/>
								<div className="absolute top-0 h-full w-full">
									<Image
										src="images/home/businessanddriver/black-map.svg"
										width={335}
										height={400}
										objectFit="cover"
										layout="fill"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default ServicePartners;
