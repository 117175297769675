import { faChevronDown, faChevronUp, faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import Collapsible from 'react-collapsible';

import Card from '@/components/Card';
import Container from '@/components/Container';
import Icon from '@/components/Icon';
import { LinkExternal } from '@/components/Link';
import Ranger from '@/components/Ranger';
import { Body, Headline } from '@/components/Text';
import InstallmentCalculator from '@/modules/Calculator/Installment';
import { IRepaymentsAccordionItems } from '@/modules/Home/Drivers/Repayments/index.d';
import { useCalculator } from '@/services/Hooks/useCalculator';
import useWindowWidth from '@/services/Hooks/useWindowWidth';

const underTheRepayments = (t: (arg0: string) => string) => [
	{
		answer: t('repayments.questions.question1.text'),
		id: 1,
		question: t('repayments.questions.question1.title'),
	},
	{
		answer: t('repayments.questions.question2.text'),
		email: (
			<div className="mb-0.5">
				<span className="mr-1">
					<Icon iconName={faEnvelope} variant="primary" />
				</span>
				<LinkExternal href={`mailto:${t('common:bumper.support')}`} variant="primary">
					{t('common:bumper.support')}
				</LinkExternal>
			</div>
		),
		id: 2,
		question: t('repayments.questions.question2.title'),
		telephone: (
			<div className="mb-0.25">
				<span className="mr-1">
					<Icon iconName={faPhone} variant="primary" />
				</span>
				<LinkExternal href={`tel:${t('common:bumper.number')}`} variant="primary">
					{t('common:bumper.number')}
				</LinkExternal>
			</div>
		),
	},
	{
		answer: t('repayments.questions.question3.text'),
		id: 3,
		question: t('repayments.questions.question3.title'),
	},
];

const RenderAccordionItems: FC<IRepaymentsAccordionItems> = ({ item }) => {
	const [isVisible, setIsVisible] = useState<Boolean>(false);

	const handleVisibility = () => {
		setIsVisible((prev) => !prev);
	};

	return (
		<div className="border-b-2 border-tertiary-contrast  pt-1">
			<Collapsible
				id="howToEarnPoints"
				onOpening={handleVisibility}
				onClosing={handleVisibility}
				trigger={
					<div className="flex justify-between mb-1">
						<Body weight="bold">{item?.question}</Body>
						<Icon iconName={isVisible ? faChevronUp : faChevronDown} variant="success" />
					</div>
				}
			>
				<Body className="pb-1">{item?.answer}</Body>
				<div className="flex flex-col">
					{item?.telephone}
					{item?.email}
				</div>
			</Collapsible>
		</div>
	);
};

const Repayments = () => {
	const { t } = useTranslation('acquisition');
	const useWidth = useWindowWidth() as number;

	const itemList = underTheRepayments(t);

	const [value, setValue] = useState<number>((parseInt(t('common:limit.loan'), 10) || 5000) / 2);

	const { minPrice } = useCalculator();

	return (
		<Container width="default" variant="tertiary" className="pt-2.5 pb-1.5 lg:py-4">
			<div className="grid grid-cols-5 col-span-5 lg:gap-x-4">
				<div className="col-span-5 lg:col-span-2">
					<Headline weight="bold">{t('repayments.title')}</Headline>
					<Body className="py-1">{t('repayments.text1')}</Body>
					<Body>{t('repayments.text2')}</Body>
					{useWidth < 768 ? (
						<div className="col-span-5 lg:col-span-3 pt-2.5">
							<Card noBorder className="rounded-sm -mt-1.5">
								<div className="w-full mb-1.5 md:mb-3">
									<Ranger setPrice={setValue} price={value} minPrice={minPrice} />
								</div>
								<InstallmentCalculator amount={value} />
							</Card>
						</div>
					) : null}
					<div className="py-2">
						<ul className="accordion-list">
							{itemList.map((item: any) => (
								<li className="accordion-list__item" key={item.id}>
									<RenderAccordionItems item={item} />
								</li>
							))}
						</ul>
					</div>
					<div className="p-1.5 bg-tertiary-contrast rounded-sm">
						<Card noBorder variant="tertiary-contrast">
							<Body>{t('repayments.info')}</Body>
						</Card>
					</div>
				</div>
				{useWidth >= 768 ? (
					<div className="col-span-5 lg:col-span-3">
						<Card
							noBorder
							className="rounded-sm -mt-1.5"
							structure="no-structure"
							padding="pt-2 px-2"
						>
							<div className="w-full mb-1.5 md:mb-3">
								<Ranger setPrice={setValue} price={value} minPrice={minPrice} />
							</div>
							<InstallmentCalculator amount={value} spacing="medium" />
						</Card>
					</div>
				) : null}
			</div>
		</Container>
	);
};

export default Repayments;
