import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import AttributedText from '@/components/AttributedText';
import Button from '@/components/Button';
import Container from '@/components/Container';
import { Variant as ContainerVariant } from '@/components/Container/index.d';
import Image from '@/components/Image';
import { Body, Caption } from '@/components/Text';
import { Variant } from '@/components/Text/index.d';

interface IProps {
	textVariant?: Variant;
	containerVariant?: ContainerVariant;
	borderVariant?: string;
}

const underTheProcess = (t: (arg0: string) => string) => [
	{
		icon: {
			height: '103',
			id: 1,
			src: 'clock.webp',
			width: '128',
		},
		id: 'preapproved',
		text: t('process.approved'),
	},
	{
		icon: {
			height: '106',
			id: 2,
			src: 'interestFree.webp',
			width: '128',
		},
		id: 'interestFree',
		text: t('process.interestFree'),
	},
	{
		icon: {
			height: '114',
			id: 3,
			src: 'scissors.webp',
			width: '128',
		},
		id: 'nationWide',
		text: t('process.nationWide'),
	},
];

const Process: FC<IProps> = ({
	textVariant = 'contrast-5',
	containerVariant = 'secondary',
	borderVariant = 'border-secondary-contrast',
}) => {
	const router = useRouter();
	const { t } = useTranslation('acquisition');
	const itemList = underTheProcess(t);

	return (
		<Container
			width="default"
			variant={containerVariant}
			className={`py-1 lg:pt-3 border-b ${borderVariant}`}
		>
			<div>
				<div className="grid grid-cols-3 gap-x-10">
					{itemList.map((item) => (
						<div
							key={item?.id}
							className="mb-2 lg:mb-0 col-span-3 lg:col-span-1 flex items-center lg:gap-x-6 lg:block"
						>
							<div className="min-w-6 md:min-h-auto lg:min-h-8.5">
								<Image
									src={`images/simplifyHomepage/${item?.icon?.src}`}
									width={item?.icon?.width}
									height={item?.icon?.height || 120}
								/>
							</div>
							<div className="ml-1 lg:ml-0">
								<AttributedText
									wrapper={Body}
									wrapperProps={{
										className: 'inline-block',
										variant: 'primary',
										weight: 'bold',
									}}
								>
									{item.text}
								</AttributedText>
							</div>
						</div>
					))}
				</div>
				<div className="flex justify-center pt-1.5 lg:pt-3">
					<Button
						label={t('process.button')}
						width="content"
						icon={faLongArrowRight}
						iconPosition="right"
						size="medium"
						onClick={() => router.push('/enter-your-reg')}
					/>
				</div>
				<Caption variant={textVariant} className="py-1 pt-3 lg:pb-5 text-center">
					{t('process.description')}
				</Caption>
			</div>
		</Container>
	);
};
export default Process;
