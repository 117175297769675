import { useSelectDealershipMutation } from '@/redux/APISlices/MarketPlaceAPI';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import {
	resetState as resetMarketplaceApplicationState,
	setRequest,
	setResult,
} from '@/redux/StateSlices/MarketplaceApplication';
import { IMarketplaceApplicationStore } from '@/redux/StateSlices/MarketplaceApplication/index.d';
import {
	resetState as resetMarketplaceMapState,
	setCreditLimit,
	setCurrentPage,
	setDealerships,
	setFullMap,
	setHoveredPartner,
	setIsMainDealershipActive,
	setMapCenterPoint,
	setScrolledPartner,
	setSelectedPartner,
	setShowPinNames,
	setShowSearch,
	setTokenFlag,
	setUserLocation,
	setVehicle,
	setZoomLevel,
} from '@/redux/StateSlices/MarketplaceMap';
import { IMarketplaceMapStore } from '@/redux/StateSlices/MarketplaceMap/index.d';
import { useAppDispatch, useTypedSelector } from '@/redux/store';

const useMarketplaceController = () => {
	const {
		vehicle,
		selectedPartner,
		fullMap,
		showSearch,
		currentPage,
		dealerships,
		mapCenterPoint,
		userLocation,
		hoveredPartner,
		scrolledPartner,
		tokenFlag,
		showPinNames,
		zoomLevel,
		creditLimit,
		isMainDealershipActive,
	} = useTypedSelector<IMarketplaceMapStore.IState>((state) => state.marketplaceMap);
	const { request, result } = useTypedSelector<IMarketplaceApplicationStore.IState>(
		(state) => state.marketplaceApplication
	);
	const [selectDealershipTrigger] = useSelectDealershipMutation();
	const dispatch = useAppDispatch();

	const flushState = () => {
		dispatch(resetMarketplaceMapState());
		dispatch(resetMarketplaceApplicationState());
	};

	const setIsMainDealershipActiveHandler = (value: boolean) => {
		dispatch(setIsMainDealershipActive(value));
	};

	const setVehicleInformation = (
		brandID: number,
		brandName: string,
		brandPicture: string,
		colour: string,
		dataType: 'api' | 'manual',
		installmentRange: string,
		year: string,
		model: string,
		motStatus?: string,
		motValidDate?: string,
		picture?: string,
		registration?: string,
		roadTaxDate?: string
	) => {
		dispatch(
			setVehicle({
				brand: {
					brand_picture: brandPicture,
					id: brandID,
					name: brandName,
				},
				colour,
				dataType,
				installmentRange,
				manufactureYear: year,
				model,
				motStatus,
				motValidDate,
				picture,
				registration,
				roadTaxDate,
			})
		);
	};

	const clearVehicleInformation = () => {
		dispatch(
			setVehicle({
				brand: {
					brand_picture: '',
					id: 0,
					name: '',
				},
				colour: '',
				dataType: 'unset',
				installmentRange: '',
				manufactureYear: '',
				model: '',
				motStatus: '',
				motValidDate: '',
				picture: '',
				registration: '',
				roadTaxDate: '',
			})
		);
	};

	const setPostcode = (code: string) => {
		dispatch(
			setRequest({
				...request,
				postcode: code,
			})
		);
	};

	const setResultHandler = (newResult: IMarketplaceApplicationStore.IApplicationResult) => {
		dispatch(
			setResult({
				...newResult,
			})
		);
	};

	const setCurrentPageHandler = (page: number) => {
		dispatch(setCurrentPage(page));
	};

	const setMapCenterPointHandler = (point: IMarketplaceMapStore.LatLong) => {
		dispatch(setMapCenterPoint(point));
	};

	const setZoomLevelHandler = (zoom: number) => {
		dispatch(setZoomLevel(zoom));
	};

	const setShowPinNamesHandler = (flag: boolean) => {
		dispatch(setShowPinNames(flag));
	};

	const setScrolledPartnerHandler = (partner: IStoreAndAPIShared.IDealership) => {
		dispatch(setScrolledPartner(partner));
	};

	const setSelectedPartnerHandler = (partner: IStoreAndAPIShared.IDealership | null) => {
		dispatch(setSelectedPartner(partner));
	};

	const setHoveredPartnerHandler = (partner: IStoreAndAPIShared.IDealership | null) => {
		dispatch(setHoveredPartner(partner));
	};

	const setFullMapHandler = (isFullMap: boolean) => {
		dispatch(setFullMap(isFullMap));
	};

	const setShowSearchHandler = (flag: boolean) => {
		dispatch(setShowSearch(flag));
	};

	const setUserCoreDetails = (email: string, dob: string, postcode: string | undefined) => {
		dispatch(setRequest({ ...request, dob, email, postcode }));
	};

	const setTokenFlagHandler = (value: boolean) => {
		dispatch(setTokenFlag(value));
	};

	const setUserToken = (value: string | string[] | undefined) => {
		dispatch(setRequest({ ...request, token: value }));
	};

	const setCreditLimitHandler = (value: number) => {
		dispatch(setCreditLimit(value));
	};

	const setInitialDealershipAndMapData = (
		dealershipData: IStoreAndAPIShared.IDealership[],
		newUserLocation: IMarketplaceMapStore.LatLong,
		newMapCenterPoint: IMarketplaceMapStore.LatLong,
		newShowSearch = false
	) => {
		dispatch(setDealerships(dealershipData));
		setCurrentPageHandler(1);
		dispatch(setUserLocation(newUserLocation));
		dispatch(setMapCenterPoint(newMapCenterPoint));
		dispatch(setShowSearch(newShowSearch));
	};

	const selectDealershipForGenericApplication = async (
		appId: string,
		dealershipId: number,
		callback: () => void
	) => {
		await selectDealershipTrigger({
			application_id: appId,
			dealership_id: dealershipId,
		});

		callback();
	};

	return {
		clearVehicleInformation,
		creditLimit,
		currentPage,
		dealerships,
		flushState,
		fullMap,
		hoveredPartner,
		isMainDealershipActive,
		mapCenterPoint,
		postcode: request.postcode || request.address.postcode,
		request,
		result,
		scrolledPartner,
		selectDealershipForGenericApplication,
		selectedPartner,
		setCreditLimitHandler,
		setCurrentPageHandler,
		setFullMapHandler,
		setHoveredPartnerHandler,
		setInitialDealershipAndMapData,
		setIsMainDealershipActiveHandler,
		setMapCenterPointHandler,
		setPostcode,
		setResultHandler,
		setScrolledPartnerHandler,
		setSelectedPartnerHandler,
		setShowPinNamesHandler,
		setShowSearchHandler,
		setTokenFlag,
		setTokenFlagHandler,
		setUserCoreDetails,
		setUserToken,
		setVehicleInformation,
		setZoomLevelHandler,
		showPinNames,
		showSearch,
		tokenFlag,
		userLocation,
		vehicle,
		zoomLevel,
	};
};

export default useMarketplaceController;
