import { DEALERSHIP_BRANDS, VEHICLE_REGISTRATION_INFO } from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';

import { IVehicleAPI } from './index.d';

export const VehicleApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		brands: builder.query<IVehicleAPI.IBrandsResponse, void>({
			query: () => ({
				method: 'GET',
				url: DEALERSHIP_BRANDS,
			}),
		}),
		vehicleInfo: builder.query<IVehicleAPI.IVehicleInfoResponse, IVehicleAPI.IVehicleInfoRequest>({
			query: ({
				registration,
				simple = true,
				image = true,
				getAdditionalData = false,
				countryCode = 'GB',
			}) => ({
				method: 'GET',
				params: {
					country_code: countryCode,
					get_additional_data: getAdditionalData,
					get_image: image,
					get_simple: simple,
					registration,
				},
				url: VEHICLE_REGISTRATION_INFO,
			}),
			transformResponse: (response: IVehicleAPI.IVehicleInfoResponse) => {
				const manufactureYear = response?.data?.manufacture_year;
				const firtsRegDateData = response?.data?.first_registration;
				if (!manufactureYear && firtsRegDateData) {
					const firtRegDate = new Date(firtsRegDateData);
					const fullYear = firtRegDate.getFullYear();
					if (fullYear) {
						return { ...response, data: { ...response.data, manufacture_year: `${fullYear}` } };
					}
					return response;
				}
				return response;
			},
		}),
	}),
	overrideExisting: false,
});

export const { useLazyVehicleInfoQuery, useVehicleInfoQuery, useLazyBrandsQuery } = VehicleApi;
