import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Container from '@/components/Container';
import { Variant as ContainerVariant } from '@/components/Container/index.d';
import { LargeBody, Title3 } from '@/components/Text';
import { Variant } from '@/components/Text/index.d';
import Trustpilot from '@/components/Widgets/Trustpilot';

interface IProps {
	containerVariant?: ContainerVariant;
	textVariant?: Variant;
}

const Reviews: FC<IProps> = ({ containerVariant = 'secondary', textVariant = 'secondary' }) => {
	const { t } = useTranslation('acquisition');

	return (
		<Container width="default" variant={containerVariant} className="py-1.5 lg:py-4 mt-2">
			<Title3 weight="bold" variant={textVariant}>
				{t('reviews.title')}
			</Title3>
			<LargeBody variant={textVariant} className="pt-1 pb-1.5">
				{t('reviews.text')}
			</LargeBody>
			<Trustpilot theme="light" textColor="blank" type="slider" height="230" />
		</Container>
	);
};

export default Reviews;
