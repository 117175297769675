import { destroyCookie } from 'nookies';
import { useDispatch } from 'react-redux';

import { UNITED_KINGDOM } from '@/constants/locales';
import { useApplyMutation } from '@/redux/APISlices/ApplicationAPI';
import { IDealershipApi } from '@/redux/APISlices/DealershipAPI/index.d';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import {
	resetState as resetApplicationState,
	setDealershipDetail,
	setInstallments,
	setRequest,
	setResult,
	setVehicle,
} from '@/redux/StateSlices/Apply';
import { IApplyApplicationStore } from '@/redux/StateSlices/Apply/index.d';
import { useTypedSelector } from '@/redux/store';
import { convertApplication } from '@/services/Forms/Converters/Application';
import { failureFormData, IApplyForm } from '@/services/Forms/Converters/Application/index.d';

const useApplication = () => {
	const dispatch = useDispatch();
	const [applyTrigger, { data: apply, isLoading: applyLoading, isError }] = useApplyMutation();
	const { applyResult, dealershipDetail, vehicle, applyRequest, installments } =
		useTypedSelector<IApplyApplicationStore.IState>((state) => state.applicationApply);

	const setApplicationApplyRequest = (newRequest: IApplyApplicationStore.IApplyRequest) => {
		dispatch(setRequest({ ...newRequest }));
	};

	const setApplicationApplyResult = (newResult: IApplyApplicationStore.IApplyResult) => {
		dispatch(setResult({ ...newResult }));
	};

	const setDealershipDetailHandler = (
		newPartner: IDealershipApi.IGetDealershipDetailsResponse | null
	) => {
		dispatch(setDealershipDetail(newPartner));
	};
	const flushState = () => {
		dispatch(resetApplicationState());
	};

	const setPostCode = (postcode: string) => {
		const data = {
			...applyRequest,
			addresses: { ...applyRequest.addresses, postcode },
			postcode,
		};
		setApplicationApplyRequest(data);
	};

	const setApplicationVehicle = (
		newVehicle: IApplyApplicationStore.IVehicle | null,
		dataType?: 'api' | 'manual'
	) => {
		dispatch(setVehicle(newVehicle ? { ...newVehicle, dataType } : null));
	};

	const clearVehicleInformation = () => {
		dispatch(
			setVehicle({
				brandPicture: '',
				dataType: 'unset',
				id: 0,
				model: '',
				name: '',
				picture: '',
				registration: '',
			})
		);
	};

	const createApplication = async (
		data: IApplyForm,
		affiliateSource?: string,
		preapprovalToken?: string,
		leadId?: string
	) => {
		const applyData = convertApplication(data, affiliateSource, preapprovalToken, leadId);
		setApplicationApplyRequest(applyData);

		const result: any = await applyTrigger(applyData);
		if (result) {
			if (result?.data?.result === 'pass') {
				destroyCookie(null, 'rc_token', {
					path: '/',
				});
			}
			setApplicationApplyResult(result?.data);
		}
	};

	const updateApplication = async (current: failureFormData, leadId?: string) => {
		const data = {
			...applyRequest,
			addresses: [
				{
					buildingname: current?.buildingName,
					buildingnumber: current?.buildingNumber,
					country: (current?.countryCode as IStoreAndAPIShared.ICountryCodes) || UNITED_KINGDOM,
					postcode: current?.postalCode,
					province: current?.province,
					street: current?.street,
					subbuildingname: current?.subBuilding,
					town: current?.city,
				},
			],
			forename: current?.forename,
			lead: leadId,
			postcode: current?.postalCode,
			surname: current?.surname,
		};
		setApplicationApplyRequest(data);
		const result: any = await applyTrigger(data);

		if (result) {
			setApplicationApplyResult(result?.data);
		}
	};

	const getAddress = () => {
		const address = applyRequest?.addresses[0];
		return address;
	};

	const setInstallmentHandler = (installmentsData: IApplyApplicationStore.IInstallment | null) => {
		dispatch(setInstallments(installmentsData));
	};

	return {
		applyLoading,
		applyRequest,
		clearVehicleInformation,
		createApplication,
		dealershipDetail,
		flushApplicationState: flushState,
		getAddress,
		installments,
		isError,
		persistedResult: applyResult,
		postcode: applyRequest?.postcode || applyRequest?.addresses[0].postcode,
		setApplicationApplyRequest,
		setApplicationApplyResult,
		setApplicationVehicle,
		setDealershipDetailHandler,
		setInstallmentHandler,
		setPostCode,
		unpersistedResult: apply,
		updateApplication,
		vehicle,
	};
};
export default useApplication;
