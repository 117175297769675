import { UNITED_KINGDOM } from '@/constants/locales';
import { IApplicationApi } from '@/redux/APISlices/ApplicationAPI/index.d';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import { IApplyForm } from '@/services/Forms/Converters/Application/index.d';
import { DateToISO, DOBToISO, FrontendtoISO } from '@/services/Utils/Date';

export const convertApplication = (
	form: IApplyForm,
	affiliateSource?: string,
	preapprovalToken?: string,
	leadId?: string
): IApplicationApi.IApplyRequest => ({
	addresses: [
		{
			apo_ae: form.apo,
			box: form.box,
			buildingname: form.buildingName,
			buildingnumber: form.buildingNumber,
			country: (form.countryCode as IStoreAndAPIShared.ICountryCodes) || UNITED_KINGDOM,
			county: form.province,
			postcode: form.postalCode,
			psc: form.psc,
			street: form.street,
			subbuildingname: form.subBuilding,
			town: form.city,
		},
	],
	affiliate: form.affiliate,
	affiliate_src: affiliateSource || undefined,
	affordability: form.affordability || undefined,
	amount: form.amount ? `${form.amount}` : '0',
	apitoken: (form.apiToken as string) || undefined,
	application_type: form.application_type,
	appointment_date: form.appointmentDate ? DateToISO(form.appointmentDate) : undefined,
	brand: form?.vehicleBrand?.name || form?.brand,
	category: form.category?.category,
	consent: form.consent,
	creditconsent: form.creditConsent || false,
	dealership: (form.dealership as number) || 'generic',
	dob: form.dob || DOBToISO(form?.yearInput, form?.monthInput, form?.dateInput),
	email: form.email,
	forename: form.forename,
	lead: leadId || undefined,
	marketing: form.marketing || undefined,
	marketing_options: form.marketing_options,
	mobile: form.mobile,
	national_id_number:
		form.dniNumber?.split(' ').join('').toUpperCase() || form.socialSecurityNumber || undefined,
	postcode: form.postalCode || undefined,
	preapproval_token: preapprovalToken || undefined,
	preapproved: form.preapproved || false,
	promocode: form.promoCode,
	ramstein:
		form.branchOfService && form.rank
			? {
					branch_of_service: form.branchOfService,
					rank: form.rank,
			  }
			: undefined,
	rc_token: form?.rc_token || null,
	registration: form.vehicleRegistration,
	repaymentdate: form.repaymentdate ? FrontendtoISO(form.repaymentdate) : undefined,
	supplier: (form.supplier as number) || undefined,
	surname: form.surname,
	verificationemail: form.email,
});
