import yup from '@/services/Forms/Schemas/BaseSchema';

const schema = yup
	.object({
		postCode: yup
			.string()
			.when('$postCodeRegex', (postCodeRegex) => {
				if (postCodeRegex) {
					return yup.string().matches(postCodeRegex, 'forms:postCode.validation.mustValid');
				}
				return yup.string().required('forms:postCode.validation.required');
			})
			.required('forms:postCode.validation.required'),
		vehicleRegistration: yup
			.string()
			.required('forms:vehicleRegistration.validation.vehicleReg.required'),
	})
	.required();
export default schema;
