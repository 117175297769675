import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Statsig } from 'statsig-react';
// @ts-ignore
import replaceAll from 'string.prototype.replaceall';

import Header from '@/modules/Home/Drivers/Header';
import HowBumperWorks from '@/modules/Home/Drivers/HowBumperWorks';
import Process from '@/modules/Home/Drivers/Process';
import Repayments from '@/modules/Home/Drivers/Repayments';
import Reviews from '@/modules/Home/Drivers/Reviews';
import ServicePartners from '@/modules/Home/Drivers/ServicePartners';
import WhyBumper from '@/modules/Home/Drivers/WhyBumper';
import IPartners from '@/modules/Marketplace/Network/MapView/Partners/index.d';
import { useLazyVehicleInfoQuery } from '@/redux/APISlices/VehicleAPI';
import schema from '@/services/Forms/Schemas/Marketplace/SwitcherTwoHeader';
import useApplication from '@/services/Hooks/useApplication';
import { useGoogleTagManager } from '@/services/Hooks/useGoogleTagManager';
import useLocalizedRegex from '@/services/Hooks/useLocalizedRegex';
import useMarketplaceController from '@/services/Hooks/useMarketplaceController';

const HomeForDrivers = () => {
	const { t } = useTranslation('forms');
	const router = useRouter();
	const { setVehicleInformation } = useMarketplaceController();
	const { setApplicationVehicle } = useApplication();
	const sendEvent = useGoogleTagManager();

	const [showBrand, setShowBrand] = useState<boolean>(false);

	const [trigger, { data, isFetching, isError, error, isSuccess, isLoading }] =
		useLazyVehicleInfoQuery();

	const regex = useLocalizedRegex();

	const formReturn = useForm<IPartners.SearchForm>({
		context: {
			postCodeRegex: regex?.postCode?.partial || regex?.postCode?.full,
			showBrand,
		},
		mode: 'onBlur',
		resolver: yupResolver(schema),
	});

	const { setError } = formReturn;

	const vehicleRegError = (errorMessage: string) => {
		setError('vehicleRegistration', {
			message: errorMessage,
		});

		sendEvent('form error', {
			error: {
				description: errorMessage,
				name: 'form field error',
			},
			form: {
				fieldName: 'vehicle registration',
				name: 'simplify homepage',
			},
		});
	};

	const onSubmit = async (d: any) => {
		Statsig.logEvent('find_a_service_partner_in_homepage');

		if (!isError || !showBrand) {
			if (regex.vehicleReg.test(replaceAll(d.vehicleRegistration, /\s/g, ''))) {
				setApplicationVehicle({ ...d, registration: d.vehicleRegistration });
				trigger({ image: true, registration: d.vehicleRegistration, simple: true });
			} else {
				const errorMessage = t('vehicleRegistration.validation.regVerify.message');
				vehicleRegError(errorMessage);
			}
		}
	};

	useEffect(() => {
		if (isError && !isSuccess) {
			const errorMessage = t('vehicleRegistration.validation.regVerify.notFound');
			vehicleRegError(errorMessage);
			setShowBrand(true);
		}
		if (isSuccess && !isError) {
			const vehicleData = {
				brandPicture: data?.data?.brand_picture || '',
				colour: data?.data?.colour || '',
				id: data?.data?.brand_id,
				manufactureYear: data?.data?.manufacture_year || '',
				model: data?.data?.model || '',
				motStatus: data?.data?.mot_status || '',
				name: data?.data?.brand_name || '',
				picture: data?.data?.picture,
				registration: data?.data?.registration,
				roadTaxData: data?.data?.tax_due_date || '',
			};
			setApplicationVehicle(vehicleData, 'api');
			setVehicleInformation(
				data?.data?.brand_id || 0,
				data?.data?.brand_name || '',
				data?.data?.brand_picture || '',
				data?.data?.colour || '',
				'api',
				'',
				data?.data?.manufacture_year || '',
				data?.data?.model || '',
				data?.data?.mot_status || '',
				data?.data?.mot_expiry_date || '',
				data?.data?.picture || '',
				data?.data?.registration || '',
				data?.data?.tax_due_date || ''
			);

			router.push({
				pathname: 'customer/new/apply',
				query: {
					type: 'switchwebsite',
				},
			});
		}
	}, [isError, error, isSuccess]);

	return (
		<>
			<Header
				showBrand={showBrand}
				onSubmit={onSubmit}
				setShowBrand={setShowBrand}
				isFetching={isFetching}
				form={formReturn}
				isLoading={isLoading}
			/>
			<HowBumperWorks />
			<Process
				textVariant="contrast-30"
				containerVariant="reverse"
				borderVariant="border-tertiary"
			/>
			{!showBrand ? (
				<>
					<ServicePartners containerVariant="reverse" textVariant="primary" />
					<WhyBumper containerVariant="reverse" />
					<Reviews containerVariant="tertiary" textVariant="primary" />
					<Repayments />
				</>
			) : null}
		</>
	);
};

export default HomeForDrivers;
