import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import Container from '@/components/Container';
import { Variant as ContainerVariant } from '@/components/Container/index.d';
import Image from '@/components/Image';
import { Body, LargeBody, Title1, Title2 } from '@/components/Text';

interface IProps {
	containerVariant?: ContainerVariant;
}

const underTheProcess = (t: (arg0: string) => string) => [
	{
		icon: {
			height: '96',
			id: 1,
			src: 'suv.svg',
			width: '184',
		},
		id: 'vehicleReg',
		number: '1.',
		text: t('whyBumper.processes.vehicleReg.text'),
		title: t('whyBumper.processes.vehicleReg.title'),
	},
	{
		icon: {
			height: '96',
			id: 2,
			src: 'car-key.svg',
			width: '124',
		},
		id: 'preapproved',
		number: '2.',
		text: t('whyBumper.processes.preapproved.text'),
		title: t('whyBumper.processes.preapproved.title'),
	},
	{
		icon: {
			height: '96',
			id: 3,
			src: 'calendar.svg',
			width: '81',
		},
		id: 'nationWide',
		number: '3.',
		text: t('whyBumper.processes.cost.text'),
		title: t('whyBumper.processes.cost.title'),
	},
];

const WhyBumper: FC<IProps> = ({ containerVariant = 'secondary' }) => {
	const { t } = useTranslation('acquisition');
	const itemList = underTheProcess(t);
	const router = useRouter();

	return (
		<Container width="default" variant={containerVariant} className="py-2.5 lg:py-4">
			<div className="py-3">
				<Body variant="reverse" weight="bold">
					{t('whyBumper.subTitle')}
				</Body>
				<LargeBody weight="bold" className="py-0.5">
					{t('whyBumper.text')}
				</LargeBody>
				<Title1 variant="reverse" className="uppercase pb-3">
					{t('whyBumper.title')}
				</Title1>
				<div className="grid grid-cols-3 gap-x-3">
					{itemList.map((item) => (
						<div key={item?.id} className="col-span-3 lg:col-span-1">
							<div className="text-right relative top-10 md:pl-3 md:text-left lg:pl-0 lg:top-0">
								<Image
									src={`images/simplifyHomepage/${item?.icon?.src}`}
									width={item?.icon?.width}
									height={item?.icon?.height || 96}
								/>
							</div>
							<div>
								<Title2 variant="reverse">{item?.number}</Title2>
								<Body weight="bold" className="pt-0.5 pb-1.5">
									{item?.title}
								</Body>
								<Body>{item?.text}</Body>
							</div>
						</div>
					))}
				</div>
				<div className="flex justify-center pt-1.5 lg:pt-3">
					<Button
						label={t('whyBumper.button')}
						width="content"
						icon={faLongArrowRight}
						iconPosition="right"
						size="medium"
						onClick={() => router.push('/enter-your-reg')}
					/>
				</div>
			</div>
		</Container>
	);
};

export default WhyBumper;
