import {
	GET_DEALERSHIP_BY_POSTCODE,
	GET_OEM_NEARBYDEALERSHIPS,
	SELECT_DEALERSHIP,
} from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';
import { IMarketplaceApi } from '@/redux/APISlices/MarketPlaceAPI/index.d';

export const MarketplaceApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		getDealershipsByPostcode: builder.query<
			IMarketplaceApi.IGetDealershipsByPostcodeResponse,
			IMarketplaceApi.IGetDealershipsByPostcodeRequest
		>({
			query: ({ brandId, postCode, limit, radius, popularAffiliates }) => ({
				method: 'GET',
				params: {
					brand_id: brandId,
					limit,
					popularaffiliates: popularAffiliates,
					postcode: postCode,
					radius,
				},
				url: GET_DEALERSHIP_BY_POSTCODE,
			}),
		}),
		getOemNearbydealerships: builder.query<
			IMarketplaceApi.IGetOEMNearbydealershipsResponse,
			IMarketplaceApi.IGetOEMNearbydealershipsRequest
		>({
			query: ({ brandId, postCode }) => ({
				method: 'GET',
				params: {
					brand_id: brandId,
					postcode: postCode,
				},
				url: GET_OEM_NEARBYDEALERSHIPS,
			}),
		}),
		selectDealership: builder.mutation<
			IMarketplaceApi.ISelectDealershipResponse,
			IMarketplaceApi.ISelectDealershipRequest
		>({
			query: ({ application_id, dealership_id }) => ({
				data: {
					application_id,
					dealership_id,
				},
				method: 'POST',
				url: SELECT_DEALERSHIP,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useLazyGetDealershipsByPostcodeQuery,
	useLazyGetOemNearbydealershipsQuery,
	useSelectDealershipMutation,
} = MarketplaceApi;
