import { useTranslation } from 'next-i18next';
import { FC, useEffect, useRef, useState } from 'react';

import { ITrustpilot } from '@/components/Widgets/Trustpilot/index.d';
import { subscribe, unsubscribe } from '@/services/Events';
import useBrowserMounted from '@/services/Hooks/useBrowserMounted';

const BUSINESS_UNIT_ID = '6139e159339f2f001dc81d8c';

const getTPTemplateId = (id: string) => {
	if (id === 'horizontal') {
		return '5419b6ffb0d04a076446a9af';
	}
	if (id === 'carousel') {
		return '53aa8912dec7e10d38f59f36';
	}
	if (id === 'mini') {
		return '53aa8807dec7e10d38f59f32';
	}
	if (id === 'slider') {
		return '54ad5defc6454f065c28af8b';
	}
	return null;
};

const TrustpilotWidget: FC<ITrustpilot.IProps> = ({
	type,
	height,
	theme,
	textColor,
	className = '',
}) => {
	const getColorTextCode = (color: ITrustpilot.Color) => {
		switch (color) {
			case 'white':
				return '#ffffff';
			case 'black':
				return '#1b1b1b';
			case 'blank':
				return '';
			default:
				return '';
		}
	};

	const { t } = useTranslation('common');
	const trustpilotRef = useRef(null);
	const [isInitialized, setIsinitialized] = useState<boolean>(false);
	const mounted = useBrowserMounted();
	const templateId = getTPTemplateId(type);
	const textColorCode = getColorTextCode(textColor);

	const renderTheElement = () => {
		if (window.Trustpilot && trustpilotRef.current && !isInitialized) {
			window.Trustpilot.loadFromElement(trustpilotRef.current, true);
			setIsinitialized(true);
		}
	};

	useEffect(() => {
		renderTheElement();
	}, [mounted, type, theme, textColor, trustpilotRef]);

	useEffect(() => {
		subscribe('TrustpilotReady', () => {
			renderTheElement();
		});
		return () => {
			unsubscribe('TrustpilotReady', () => null);
		};
	}, []);

	return mounted ? (
		<div
			className={className}
			ref={trustpilotRef}
			data-locale={t('trustpilot.isoCode')}
			data-template-id={templateId}
			data-businessunit-id={BUSINESS_UNIT_ID}
			data-style-height={height}
			data-style-width="100%"
			data-theme={theme}
			data-text-color={textColorCode}
			data-stars="5"
			data-review-languages={t('trustpilot.reviewLanguage')}
		>
			<a href="https://uk.trustpilot.com/review/bumper.co" target="_blank" rel="noreferrer">
				Trustpilot
			</a>
		</div>
	) : null;
};

export default TrustpilotWidget;
