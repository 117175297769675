import { faLongArrowLeft, faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@/components/Button';
import FormControl from '@/components/Form';
import Icon from '@/components/Icon';
import Label from '@/components/Label';
import Select from '@/components/Select';
import { Headline, LargeBody } from '@/components/Text';
import { IBrandManufacturer } from '@/modules/Home/Drivers/Header/BrandManufacturer/index.d';
import TrustpilotLabel from '@/modules/Home/TrustpilotLabel';
import { useLazyBrandsQuery } from '@/redux/APISlices/VehicleAPI';
import schema from '@/services/Forms/Schemas/Marketplace/SwitcherTwoHeader/BrandManufacturer';
import { resolveFormVariant } from '@/services/Forms/Validation/Methods/resolveFormVariant';
import useApplication from '@/services/Hooks/useApplication';
import { useGoogleTagManager } from '@/services/Hooks/useGoogleTagManager';

const BrandManufacturer: FC<IBrandManufacturer> = ({ setShowBrand }) => {
	const { t } = useTranslation('acquisition');
	const router = useRouter();
	const sendEvent = useGoogleTagManager();
	const { setApplicationVehicle, vehicle } = useApplication();

	const [brandsTrigger, brandsResult] = useLazyBrandsQuery();

	const handleGoBack = () => {
		setShowBrand(false);
	};

	const formReturn = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	});

	const {
		handleSubmit,
		control,
		formState: { errors, dirtyFields },
	} = formReturn;

	const onSubmitButtonClick = () => {
		sendEvent('form submission', {
			form: {
				name: 'find a partner',
			},
		});
	};

	const onSubmit = async (d: any) => {
		const { name, id } = d?.vehicleBrand;

		const vehicleData = {
			id: d?.vehicleBrand?.id || 0,
			name: d?.vehicleBrand?.name || '',
			picture: d?.vehicleBrand?.image_url || '',
			registration: vehicle?.registration || '',
		};
		setApplicationVehicle(vehicleData);
		if (name && id) {
			router.push({
				pathname: 'customer/new/apply',
				query: {
					type: 'switchwebsite',
				},
			});
		}
	};

	useEffect(() => {
		brandsTrigger();
	}, []);

	return (
		<div className="py-0">
			<div className="max-w-md">
				<div role="button" onClick={handleGoBack} onKeyDown={handleGoBack} tabIndex={0}>
					<Icon iconName={faLongArrowLeft} className="text-n4 cursor-pointer mb-1" />
				</div>
				<Headline>{t('header.brandManufacturer.title')}</Headline>
				<LargeBody>{t('header.brandManufacturer.text')}</LargeBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormControl className="my-1.5">
						<Label label={t('header.brandManufacturer.label')} />
						<Controller
							control={control}
							name="vehicleBrand"
							render={({ field: { onChange, onBlur, value } }) => (
								<Select
									options={brandsResult?.data || []}
									onChange={onChange}
									onBlur={onBlur}
									isLoading={brandsResult?.isLoading}
									isDisabled={brandsResult?.isLoading}
									value={value}
									variant={resolveFormVariant('vehicleBrand', errors, dirtyFields)}
									placeholder={t('forms:choose')}
									customErrorLabelVariant="primary"
								/>
							)}
						/>
					</FormControl>
					<Button
						label={t('header.brandManufacturer.button')}
						width="full"
						icon={faLongArrowRight}
						iconPosition="right"
						size="medium"
						onClick={() => onSubmitButtonClick()}
					/>
				</form>

				<div className="pt-1.5 flex justify-center">
					<TrustpilotLabel />
				</div>
			</div>
		</div>
	);
};

export default BrandManufacturer;
