import {
	ACCEPT_OFFER,
	APPLICATION_SEND_LINK,
	APPLY,
	CANCEL,
	CREATE_LEAD,
	DEALERSHIP_APPLICATIONS,
	DEALERSHIP_SERVICE_INFORMATION,
	IDCONSUMER,
	INVOICE,
	RETENTION_URL,
	UPDATE,
} from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';
import { IApplicationApi } from '@/redux/APISlices/ApplicationAPI/index.d';

export const ApplicationApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		IDConsumer: builder.mutation<
			IApplicationApi.IIDConsumerResponse,
			IApplicationApi.IIDConsumerRequest
		>({
			query: (body) => ({
				data: body,
				method: 'POST',
				url: IDCONSUMER,
			}),
		}),
		acceptOffer: builder.mutation<
			IApplicationApi.IAcceptOfferResponse,
			IApplicationApi.IAcceptOfferRequest
		>({
			invalidatesTags: ['ApplicationData'],
			query: (body) => ({
				data: body,
				method: 'POST',
				url: ACCEPT_OFFER,
			}),
		}),
		apply: builder.mutation<IApplicationApi.IApplyResponse, IApplicationApi.IApplyRequest>({
			invalidatesTags: ['ApplicationData'],
			query: (body) => ({
				data: body,
				method: 'POST',
				url: APPLY,
			}),
		}),
		cancel: builder.mutation<IApplicationApi.ICancelResponse, IApplicationApi.ICancelRequest>({
			invalidatesTags: ['DealershipData'],
			query: (body) => ({
				data: body,
				method: 'POST',
				url: CANCEL,
			}),
		}),
		createLead: builder.mutation<
			IApplicationApi.ICreateLeadResponse,
			IApplicationApi.ICreateLeadRequest
		>({
			invalidatesTags: ['ApplicationData'],
			query: (body) => ({
				data: body,
				method: 'POST',
				url: CREATE_LEAD,
			}),
		}),
		getDealershipApplication: builder.query<IApplicationApi.IGetDealershipApplicationResponse, any>(
			{
				keepUnusedDataFor: 5,
				providesTags: ['DealershipData'],
				query: ({ dealership, status, page, search, ordering }) => ({
					method: 'GET',
					params: {
						dealership,
						ordering,
						page,
						search,
						status,
					},
					url: DEALERSHIP_APPLICATIONS,
				}),
			}
		),

		getDealershipServiceInformation: builder.query<
			IApplicationApi.IGetDealershipServiceInformation,
			void
		>({
			query: () => ({
				method: 'GET',
				params: {},
				url: DEALERSHIP_SERVICE_INFORMATION,
			}),
		}),

		//	Retention
		getRetentionEmailRetrieve: builder.query<
			IApplicationApi.IGetRetentionEmailRetrieveResponse,
			IApplicationApi.IGetRetentionEmailRetrieveRequest
		>({
			query: (args) => ({
				method: 'GET',
				params: {
					token: args.Token,
				},
				url: RETENTION_URL,
			}),
		}),

		getRetentionRetrieve: builder.query<
			IApplicationApi.IGetRetentionRetrieveResponse,
			IApplicationApi.IGetRetentionRetrieveRequest
		>({
			providesTags: ['ApplicationData'],
			query: (args) => ({
				method: 'GET',
				params: {
					dob: args.Dob,
					email: args.Email,
					postcode: args.Postcode,
					token: args.Token,
				},
				url: `${RETENTION_URL}/dealership`,
			}),
		}),
		invoice: builder.mutation<any, any>({
			query: (body) => ({
				data: body,
				formData: true,
				headers: {
					'Content-Type': 'multipart/form-data;',
				},
				method: 'POST',
				url: INVOICE,
			}),
		}),
		postApplications: builder.mutation<any, any>({
			query: (body) => ({
				data: body,
				method: 'POST',
				url: DEALERSHIP_APPLICATIONS,
			}),
		}),
		sendlink: builder.mutation<
			IApplicationApi.ISendlinkResponse,
			IApplicationApi.ISendLinkRequest | IApplicationApi.ISendPaymentRequest
		>({
			query: (body) => ({
				data: body,
				method: 'POST',
				url: APPLICATION_SEND_LINK,
			}),
		}),
		update: builder.mutation<IApplicationApi.IUpdateResponse, IApplicationApi.IUpdateRequest>({
			query: (body) => ({
				data: body,
				method: 'POST',
				url: UPDATE,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useApplyMutation,
	useCreateLeadMutation,
	useCancelMutation,
	useUpdateMutation,
	useIDConsumerMutation,
	useAcceptOfferMutation,
	useSendlinkMutation,
	useInvoiceMutation,
	useLazyGetDealershipApplicationQuery,
	useLazyGetRetentionRetrieveQuery,
	useGetRetentionEmailRetrieveQuery,
	usePostApplicationsMutation,
	useGetDealershipServiceInformationQuery,
} = ApplicationApi;
