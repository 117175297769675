import { FC } from 'react';

import Container from '@/components/Container';
import Image from '@/components/Image';
import ImageContainer from '@/components/ImageContainer';
import BrandManufacturer from '@/modules/Home/Drivers/Header/BrandManufacturer';
import { ISimplifyHomepageHeader } from '@/modules/Home/Drivers/Header/index.d';
import SimplifyHomepageVehicleReg from '@/modules/Home/Drivers/Header/VehicleReg';

const Header: FC<ISimplifyHomepageHeader> = ({
	showBrand,
	onSubmit,
	setShowBrand,
	isFetching,
	form,
	isLoading,
}) => (
	<>
		<ImageContainer
			variant="chilling-on-road"
			width="full"
			spacing="none"
			bgSize="cover"
			bgPosition="right-bottom"
			padding="none"
			wrapperClassName="h-full w-full p-0 relative rounded-bl-md lg:rounded-0"
		>
			<div className="absolute w-full h-full inset-0 bg-gradient-to-t from-secondary-content from-0% via-%30 to-50%" />
			<div className="relative lg:w-1/2 ">
				<div className="absolute inset-0 bg-primary lg:opacity-95 lg:rounded-bl-none lg:rounded-br-md" />
				<Container
					width="default"
					variant="transparent"
					className="relative z-10 pb-1.5 pt-6 lg:pt-8 lg:pb-4 lg:px-5 flex flex-col justify-center w-full min-h-full"
					wrapperClassName="rounded-b-md"
				>
					{!showBrand ? (
						<SimplifyHomepageVehicleReg
							onSubmit={onSubmit}
							setShowBrand={setShowBrand}
							isFetching={isFetching}
							form={form}
							isLoading={isLoading}
						/>
					) : null}
					{showBrand ? (
						<BrandManufacturer showBrand={showBrand} setShowBrand={setShowBrand} />
					) : null}
				</Container>
			</div>
		</ImageContainer>
		<div className="block lg:hidden relative">
			<Image
				src="images/home/businessanddriver/HomeForDriversHeaderBackground.jpeg"
				height={187}
				width={325}
				layout="responsive"
			/>
		</div>
	</>
);
export default Header;
