import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import Icon from '@/components/Icon';
import { ITooltip, Variant } from '@/components/Tooltip/index.d';

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'primary':
			return 'var(--tertiary)';
		default:
			return 'var(--tertiary)';
	}
};

const Tooltip: FC<ITooltip.IProps> = ({ label, variant = 'primary' }) => {
	const color = getColor(variant);

	return (
		<>
			<Icon
				iconName={faInfoCircle}
				data-tip
				data-for="inputInfo"
				className="mx-0.25 w-5 h-5"
				variant="tone-contrast-30"
			/>
			<ReactTooltip
				backgroundColor={color}
				arrowColor={color}
				id="inputInfo"
				effect="solid"
				type="dark"
				textColor="#000000"
			>
				{label}
			</ReactTooltip>
		</>
	);
};

export default Tooltip;
