import { FC } from 'react';

import Icon from '@/components/Icon';
import { ILabel } from '@/components/Label/index.d';
import { Body } from '@/components/Text';
import Tooltip from '@/components/Tooltip';

const Label: FC<ILabel.IProps> = ({
	icon,
	label,
	tooltip,
	dataCy,
	optionalText,
	iconVariant = 'reverse',
	textVariant = 'primary',
}) => (
	<div className="flex flex-row items-center mb-0.5">
		{icon ? <Icon iconName={icon} variant={iconVariant} className="mr-0.25 w-5 h-5" /> : undefined}
		<Body data-cy={`${dataCy}Label`} weight="bold" variant={textVariant}>
			{label}
		</Body>
		{tooltip && <Tooltip label={tooltip} />}
		{optionalText && (
			<Body data-cy={`${dataCy}OptionalText`} variant="contrast-30" className="mx-0.25">
				({optionalText})
			</Body>
		)}
	</div>
);

export default Label;
