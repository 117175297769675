import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { Statsig } from 'statsig-react';

import Button from '@/components/Button';
import FormControl from '@/components/Form';
import Input from '@/components/Input';
import { LinkInternal } from '@/components/Link';
import { Caption, LargeBody, Title1 } from '@/components/Text';
import { SimplifyHomepageVehicleRegTypes } from '@/modules/Home/Drivers/Header/VehicleReg/index.d';
import TrustpilotLabel from '@/modules/Home/TrustpilotLabel';
import { resolveFormVariant } from '@/services/Forms/Validation/Methods/resolveFormVariant';
import { useGoogleTagManager } from '@/services/Hooks/useGoogleTagManager';
import useMarketplaceController from '@/services/Hooks/useMarketplaceController';

const SimplifyHomepageVehicleReg: FC<SimplifyHomepageVehicleRegTypes.ISimplifyHomepageHeader> = ({
	onSubmit,
	setShowBrand,
	isFetching,
	form,
	isLoading,
}) => {
	const { t } = useTranslation('home');
	const { clearVehicleInformation } = useMarketplaceController();
	const sendEvent = useGoogleTagManager();

	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors, dirtyFields },
	} = form;

	const onVehicleRegFocused = () => {
		sendEvent('form field focused', {
			form: {
				fieldName: 'vehicle registration',
				name: 'find a partner',
			},
		});
	};

	const onSubmitButtonClick = () => {
		sendEvent('form submission', {
			form: {
				name: 'find a partner',
			},
		});
	};

	return (
		<div className="w-full">
			<div className="pb-1.5">
				<TrustpilotLabel />
			</div>
			<div>
				<Title1 className="uppercase text-n8">{t('forDrivers.landing.header')}</Title1>
				<LargeBody className="pt-0.5 pb-1.5">{t('forDrivers.landing.description')}</LargeBody>
			</div>
			<div className="lg:mr-8">
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormControl className="mb-1">
						<Input
							showLoader={isFetching}
							placeholder={t('forms:yourVehicleReg')}
							errors={
								errors?.vehicleRegistration?.message
									? t(errors?.vehicleRegistration?.message)
									: undefined
							}
							{...register('vehicleRegistration')}
							onChange={(e) => {
								register('vehicleRegistration').onChange(e);
								setShowBrand(false);
								resetField('vehicleBrand');
								clearVehicleInformation();
							}}
							onFocus={() => Statsig.logEvent('homepage_vehiclereg_click')}
							onClick={() => onVehicleRegFocused()}
							variant={resolveFormVariant('vehicleRegistration', errors, dirtyFields)}
							customBackground="warning"
							customInput
							customInputText="UK"
							customInputImage="images/flags/gb_rect.svg"
							customErrorLabelVariant="primary"
							className="placeholder-tone-contrast-30 font-bold text-n2 md:text-n4 uppercase h-16 outline-none w-full bg-warning rounded-full pl-1"
						/>
					</FormControl>
					<Button
						showLoader={isLoading}
						label={t('forDrivers.landing.vehicleRegButton')}
						id="vehicleRegSubmit"
						width="full"
						icon={faLongArrowRight}
						iconPosition="right"
						size="x-large"
						onClick={() => onSubmitButtonClick()}
					/>
					<Caption className="py-1.5">
						<span>{t('forDrivers.landing.creditSubject')} </span>
						<LinkInternal href="/terms" variant="primary" className="underline">
							{t('forDrivers.landing.termsAndConditionsApply')}
						</LinkInternal>
					</Caption>
				</form>
			</div>
		</div>
	);
};

export default SimplifyHomepageVehicleReg;
