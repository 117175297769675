import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Image from '@/components/Image';
import { Body } from '@/components/Text';
import { TrustpilotLabelTypes } from '@/modules/Home/TrustpilotLabel/index.d';

const TrustpilotLabel: FC<TrustpilotLabelTypes.ITrustpilotLabel> = ({ variant = 'primary' }) => {
	const { t } = useTranslation('home');

	return (
		<div className="flex gap-0.5 items-center">
			<Body variant={variant} weight="bold" className="mr-0.5">
				{t('excellent')}
			</Body>
			<Image src="images/banners/trustpilotRating.svg" height={24} width={128} objectFit="fill" />
			<Image
				src={
					variant === 'secondary'
						? 'images/banners/trustpilotStarWhite.svg'
						: 'images/banners/trustpilotStar.svg'
				}
				height={24}
				width={95}
				objectFit="fill"
			/>
		</div>
	);
};

export default TrustpilotLabel;
